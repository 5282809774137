:root {
	--clr-header-primary-400: #170d20;
	--clr-header-primary-200: #ff872a;
}

.chevron::after {
	content: "\f107";
	margin-left: 1ch;
	font-weight: 900;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	display: inline-block;
	transition: all 400ms ease-in-out;
	font-family: "Font Awesome 5 Free";
	-webkit-font-smoothing: antialiased;
}

header {
	text-align: center;
	position: fixed;
	z-index: 999;
	width: 100%;
}

.headerContainer {
	will-change: background;
	background: linear-gradient(rgba(0,0,0, 0.7), rgba(0,0,0, 0.0));
	transition: background 0ms ease-in-out 500ms;
}

.headerContainer.scrolled {
	background: var(--clr-header-primary-400);
}

.logo {
	width: auto;
	height: 5.3125rem;
}

.navToggle,
.productsToggle,
.companyToggle {
	display: none;
}

.navToggle:checked ~ .headerContainer {
	transition: background 0ms ease-in-out;
	background: var(--clr-header-primary-400);
}

.navToggle:checked ~ .headerContainer nav {
	transform: scale(1, 1);
}

.navToggle:checked ~ .headerContainer nav a,
.navToggle:checked ~ .headerContainer nav .productsLabel,
.navToggle:checked ~ .headerContainer nav .companyLabel {
	transition: opacity 250ms ease-in-out 250ms;
	opacity: 1;
}

.navToggleLabel {
	align-items: center;
	position: absolute;
	margin-left: 1em;
	display: flex;
	height: 100%;
	left: 0;
	top: 0;
}

.navToggleLabel span {
	display: block;
	color: white;
	font-size: 2rem;
	position: relative;
	border-radius: 2px;
}

nav {
	transition: transform 400ms ease-in-out;
	transform: scale(1, 0);
	transform-origin: top;
	background: var(--clr-header-primary-400);
	position: absolute;
	text-align: left;
	width: 100%;
	top: 100%;
	left: 0;
}

nav ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

nav li {
	margin-left: 1em;
	margin-bottom: 1em;
}

nav a,
.productsLabel,
.companyLabel {
	opacity: 0;
	color: #FFF;
	font-size: 1.2rem;
	text-decoration: none;
	transition: color 200ms ease-in-out, opacity 250ms ease-in-out;
}

nav a:hover,
nav a:active,
nav a.active {
	color: var(--clr-header-primary-200);
}

.productsToggle:checked ~ li .productsLabel.chevron::after,
.companyToggle:checked ~ li .companyLabel.chevron::after {
	transform: rotateZ(-180deg);
	color: var(--clr-header-primary-200);
}

.productsToggle:checked ~ li .productsSubNav,
.companyToggle:checked ~ li .companySubNav {
	display: block;
}

.productsSubNav, .companySubNav {
	margin-top: 1em;
	margin-left: 1em;
	display: none;
}

@media screen and (min-width: 880px) {
	.headerContainer {
		display: grid;
		transition: unset;
		grid-template-columns: 1fr auto minmax(600px, 3fr) 1fr;
	}

	.headerContainer:hover {
		transition: unset;
		background: var(--clr-header-primary-400);
	}

	.navToggleLabel {
		display: none;
	}

	.logo {
		grid-column: 2 / span 1;
	}

	nav {
		all: unset;
		display: flex;
		align-items: center;
		grid-column: 3 / span 1;
		justify-content: flex-end;
	}

	nav ul {
		height: 100%;
		display: flex;
	}

	nav li {
		margin-left: 3em;
		margin-bottom: 0;
	}

	nav a {
		opacity: 1;
		height: 100%;
		display: flex;
		align-items: center;
	}

	nav li {
		position: relative;
	}

	.productsLabel, .companyLabel {
		opacity: 1;
		height: 100%;
		display: flex;
		align-items: center;
	}

	.productsContainer,
	.companyContainer {
		height: 100%;
		position: relative;
	}

	.productsContainer:hover .productsLabel.chevron::after,
	.companyContainer:hover .companyLabel.chevron::after {
		transform: rotateZ(-180deg);
		color: var(--clr-header-primary-200);
	}

	.productsContainer:hover .productsSubNav,
	.companyContainer:hover .companySubNav {
		opacity: 1;
		display: block;
		background: var(--clr-header-primary-400);
	}

	.productsSubNav,
	.companySubNav {
		margin: 0;
		opacity: 1;
		padding: 1em;
		width: 12.5rem;
		border-radius: 5px;
		position: absolute;
	}

	.productsSubNav ul li,
	.companySubNav ul li {
		margin: 0;
	}
}
