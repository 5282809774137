:root {
	--clr-primary-100: #fff;
	--clr-primary-200: #f6f9fe;
	--clr-primary-300: #e1e1e1;
	--clr-primary-400: #160f1d;
	--clr-primary-500: #ff872a; 
}

*, *::before, *, *::after {
	box-sizing: border-box;
}

html, body, #app {
	margin: 0;
	padding: 0;
}

html, body {
	min-height: 100vh;
	width: 100%;
	background: var(--clr-primary-200);
	font-family: 'Helvetica Neue', arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-weight: 400;
	color: #444;
}

footer img {
	width: auto;
	height: 2rem;
}

section + * {
	padding: 3rem 0;
}

#footer-bottom {
	display: flex;
	flex-direction: row;
	margin: 1rem 0 0;
}

.parent-company {
	width: 9rem;
	height: auto;
}

.awards {
	gap: .5rem;
	width: min(70%, 40rem);
	display: grid;
	place-items: center;
	grid-template-columns: repeat(3, 1fr);
	margin-inline: auto;
}

.award {
	width: min(100%, 6.25rem);
	height: auto;
	transform: scale(1);
	transition: transform 100ms ease-in-out;
}

.award:hover {
	transform: scale(1.1);
}

@media screen and (max-width: 824px) {
	#footer-bottom {
		flex-direction: column;
	}
}

@-moz-document url(about:blank) {
    html,body { background: #38383d; }
}